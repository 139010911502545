html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Montserrat;
  color: #fff;
  overflow-x: hidden;
  width: 100vw;
}

.menu {
  width: 100%;
  max-width: 100vw;
  position: fixed;
  left: 0;
  top: 0px;
  padding-bottom: 20px;
  padding-top: 10px;
  margin: 0;
  min-height: 78px;
  background-color: #000000;
  z-index: 1001;
}

.menu img {
  float: left;
  width: 20%;
  margin-right: 30px;
}

.menu-items {
  float: right;
  margin-top: 12.5px;
  width: 440px;
}

.menu-items a {
  text-decoration: none;
  color: #fff;
  margin-right: 30px;
}

.menu-items a:hover {
  font-weight: 600;
}

.margins {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 98px;
}

.margins-splint {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 98px;
}

.left-column {
  width: 30%;
}

section {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgb(0,0,0);
  background: -moz-linear-gradient(121deg, rgba(0,0,0,1) 55%, rgba(50,50,50,1) 100%);
  background: -webkit-linear-gradient(121deg, rgba(0,0,0,1) 55%, rgba(50,50,50,1) 100%);
  background: linear-gradient(121deg, rgba(0,0,0,1) 55%, rgba(50,50,50,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#323232",GradientType=1);
}

section .wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(img/wave.png);
  background-size: 1000px 100px;
}

section .wave.wave1 {
  animation: animate 30s linear infinite;
  z-index: 1000;
  opacity: 1;
  animation-delay: 0s;
  bottom: 0;
}

section .wave.wave2 {
  animation: animate2 15s linear infinite;
  z-index: 999;
  opacity: 0.5;
  animation-delay: -5s;
  bottom: 10px;
}

section .wave.wave3 {
  animation: animate 30s linear infinite;
  z-index: 998;
  opacity: 0.2;
  animation-delay: -2s;
  bottom: 15px;
}

section .wave.wave4 {
  animation: animate2 15s linear infinite;
  z-index: 997;
  opacity: 0.7;
  animation-delay: -3.5s;
  bottom: 20px;
}

#systems {
  scroll-margin-top: 100px;
}

#splintermind {
  scroll-margin-top: 200px;
}

@keyframes animate {
  0% {
      background-position-x: 0;
  }
  100% {
      background-position-x: 1000px;
  }
}

@keyframes animate2 {
  0% {
      background-position-x: 0;
  }
  100% {
      background-position-x: -1000px;
  }
}

.box {
  -webkit-box-shadow: 0px 0px 60px -10px rgba(115,115,115,1);
  -moz-box-shadow: 0px 0px 60px -10px rgba(115,115,115,1);
  box-shadow: 0px 0px 60px -10px rgba(115,115,115,1);
  background-color: white;
  color: black;
  height: 50vh;
  width: 75%;
  position: relative;
  top: 35px;
  left: 12.5%;
  border-radius: 25px;
  padding: 5% 10% 10% 10%;
}

p.bio {
  width: 50%;
  float: left;
  margin-right: 40px;
}

.second {
  float: left;
  width: calc(50% - 40px);
}

.second h2 {
  text-align: center;
  margin-top: -39px;
  margin-bottom: 100px;
}

.pdf {
  width: calc(121px + 121px + 80px);
  margin: auto;
}

.pdf a {
  text-decoration: none;
  color: #AF5B41;
  padding: 50px 50px 50px 50px;
  border: 2px solid #AF5B41;
  margin-left: 0px;
  margin-right: 40px;
  font-size: 22px;
  border-radius: 100%;
  text-align: center;
  transition: all .2s ease-in;
}

.pdf a:hover {
  transform: scale(1.1);
}

.column {
  -webkit-box-shadow: 0px 0px 60px -10px rgba(115,115,115,1);
  -moz-box-shadow: 0px 0px 60px -10px rgba(115,115,115,1);
  box-shadow: 0px 0px 60px -10px rgba(115,115,115,1);
  background-color: white;
  border-radius: 25px;
  padding: 2% 5% 5% 2%;
  color: black;
  width: calc(50% - 20px);
  float: left;
  min-height: 370px;
  margin-bottom: -350px;
}

.column:first-child {
  width: 50%;
  margin-right: 20px;
}

.column h1 {
  color: #AF5B41;
  text-align: center;
}

.column a {
  text-decoration: none;
  color: #AF5B41;
  font-weight: 400;
}

.column a:hover {
  font-weight: 600;
}

/* FOOTER STUFF */
/* SKEW COUNTER CLOCKWISE  */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.skew-c{
	width: 100%;
	height: 100px;
	position: default;
  background: linear-gradient(to left bottom, #fff 49%, #000000 50%);
  margin-top: 550px;
}

footer {
  width: 100%;
  background-color: black;
  position: default;
  min-height: 100px;
}

.margins-two {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}

.margins-two h2{
  font-size: 32px;
  color: #AF5B41;
  font-weight: 700;
  margin-bottom: 20px;
}

.margins-two p {
  line-height: 30px;
}

.copyright {
  text-align: center;
  font-style: italic;
  font-size: 16px;
  font-weight: 400;
  margin-top: 100px;
  padding-bottom: 5px;
}

.margins-menu {
  width: 80%;
  margin: auto;
}

.proj {
  font-size: 20px;
  font-weight: 400;
  font-style: italic;
}

span {
  font-size: 20px;
  font-weight: 200;
  font-style: italic;
}

h1.systems {
  color: black;
  text-align: left;
  left: 12.5%;
  position: relative;
  font-size: 32px;
  top: calc(0);
}

@media only screen and (max-width: 960px) {
  /* For tablets: */
  html {
    overflow-x: hidden;
  }

  h1.systems {
    text-align: center;
    left: 0;
    color: black;
    left: 0;
    position: relative;
    font-size: 32px;
    top: calc(0);
  }

  body {
    overflow-x: hidden;
  }

  .margins {
    width: 98%;
    margin-top: 98px;
  }

  .margins-splint {
    width: 98%;
    margin-top: 0px;
  }

  .left-column {
    width: 90%;
    margin: auto;
  }

  .box {
    -webkit-box-shadow: 0px 0px 60px -10px rgba(115,115,115,1);
    -moz-box-shadow: 0px 0px 60px -10px rgba(115,115,115,1);
    box-shadow: 0px 0px 60px -10px rgba(115,115,115,1);
    width: calc(100% - 60px);
    height: 100%;
    left: 0;
    margin: auto;
    top: 20px;
    margin-bottom: 60px;
    padding: 5% 5% 10% 5%;
  }

  .margins-menu {
    width: 100%;

  }

  p.bio {
    width: 100%;
    float: none;
    margin-right: 0;
  }

  .second {
    float: none;
    width: 100%;
  }

  .second h2 {
    margin-top: 10px;
    margin-bottom: 70px;
  }

  .pdf {
    width: calc(65px + 20px + 65px + 20px);
  }

  .pdf a {
    padding: 20px 20px 20px 20px;
    margin-right: 20px;
  }

  .column {
    width: calc(100% - 60px);
    margin: auto;
    float: none;
    min-height: 100%;
    padding: 2.5% 5% 5% 5%;
  }

  .column:first-child {
    width: calc(100% - 60px);
    margin: auto;
    margin-bottom: 30px;
  }

  .skew-c {
    margin-top: 60px;
  }

  .copyright {
    font-size: 12px;
  }

  /* MENU STUFF */
  .menu {
    min-height: 50px;
  }

  .menu img {
    width: 150px;
    height: 25px;
    position: absolute;
    left: calc(50% - 75px);
  }

  .proj {
    font-size: 16px;
  }

  span {
    font-size: 16px;
  }

  .menu-items {
    display: none;
    width: 0;
    float: none;
  }
}